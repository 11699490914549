import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles.css.ts"
import '../fonts/font.css';

export default function Layout(props) {
  return (
    <>
      <Head {...props} />
      <Header headerIsTransparent={props.headerIsTransparent}/>
      {props.children}
      {!props.headerIsTransparent && <Footer />}
    </>
  )
}
